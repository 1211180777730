<template>
  <app-list-view
    server-side
    app="human_asset"
    model="humanasset"
    api-url="human-asset/human-asset/"
    :title="$t('menu.humanAsset')"
    :createTo="{ name: 'humanAssetCreate' }"
    :editTo="{ name: 'humanAssetEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'humanAssetCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.company'), value: 'company.name' },
        { text: this.$t('fields.humanAssetName'), value: 'name' },
        {
          text: this.$t('fields.humanAssetCategory'),
          value: 'human_asset_category.name'
        },
        {
          text: this.$t('fields.humanAssetType'),
          value: 'human_asset_type.name'
        },
        {
          text: this.$t('fields.humanAssetGroup'),
          value: 'human_asset_group.name'
        },
        { text: this.$t('fields.date'), value: 'date' },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  }
}
</script>
